<template>
    <mercur-card v-if="isAllowedTo('SupplierCentral/getGeneratedProductsList')" class="full-height-layout fill mx-4 mb-3">
        <grid-header :quickSearch.sync="filters.search">
            {{isVirtualSupplierManagement ? 'Product Templates' : 'Products'}}
            <template slot="actions">
                <mercur-button v-if="isAllowedTo('SupplierCentral/saveProductConfiguration')" :to="addProductRoute" class="btn btn-raised btn-yellow text-uppercase">
                    <i class="fas fa-plus" />
                    Add new product {{isVirtualSupplierManagement ? 'template' : ''}}
                </mercur-button>
                <mercur-button v-if="!isVirtualSupplierManagement" class="btn btn-yellow text-uppercase" @click="openExportDialog()">
                    <i class="fas fa-download"></i>
                    Export for Fast Editor
                </mercur-button>
            </template>
        </grid-header>

        <data-table
            class="fill full-height-layout border"
            :options="options"
            :url="url"
            :quickSearch="filters.search"
        ></data-table>

        <mercur-dialog :is-open.sync="cloneProductDialogShow">
            <div slot="header">
                <h3 class="font-weight-normal mt-0">Clone product to supplier</h3>
            </div>
            <div class="px-1" v-if="allSuppliers">
                <pretty-select
                    placeholder="Supplier"
                    label="supplierName"
                    :options="allSuppliers"
                    v-model="cloneProductSupplierId"
                    :reduce="supplier => supplier.supplierId"
                ></pretty-select>
                <p>Please make sure you complete the product configurator steps after clicking the 'Clone' button</p>
            </div>
            <div slot="footer">
                <mercur-button class="btn" @click="cloneProductDialogShow = false">Close</mercur-button>
                <mercur-button class="btn btn-primary" :disabled="!cloneProductSupplierId" :to="cloneProductRoute">Clone</mercur-button>
            </div>
        </mercur-dialog>

        <mercur-dialog :is-open.sync="exportDialogShow"  @close="closeExportDialog()" width="700px">
            <div slot="header">
                <h3>Export for FastEditor</h3>
            </div>
            <div slot="default">
                <pretty-select
                    class="mb-3"
                    v-model="exportDialogConfigurationId"
                    :options="productConfigurationTemplates"
                    :reduce="template => template.productConfigurationId"
                    label="productConfigurationName"
                    custom-placeholder="Select the product you wish to export"
                >
                </pretty-select>

                <mercur-checkbox v-model="exportDialogWithBlanks">
                    With blanks
                </mercur-checkbox>
                <br/>
                <mercur-checkbox v-model="exportDialogGroupPrintColor">
                    Group by print color
                </mercur-checkbox>
            </div>
            <div slot="footer">
                <mercur-button class="btn" @click="closeExportDialog()">Close</mercur-button>
                <mercur-button class="btn btn-primary" @click="exportForFastEditor()">Export</mercur-button>
            </div>
        </mercur-dialog>
    </mercur-card>
    <div v-else>
        <p class="permission-message">Not allowed to see this view</p>
    </div>
</template>

<script>
import CONFIG from '@root/config'
import GridHeader from '@/components/GridHeader'
import DataTable from '@/components/DataTable'
import PrettySelect from '../../../components/utils/PrettySelect'
import DownloadMixin from '@/components/DownloadMixin'

export default {
    name: 'ProductsOverview',
    components: { PrettySelect, DataTable, GridHeader },
    mixins: [DownloadMixin],
    data () {
        return {
            uploadCsvPopupActive: false,
            cloneProductDialogShow: false,
            cloneProductSupplierId: this.supplierId,
            cloneProductConfigurationId: null,
            exportDialogShow: false,
            exportDialogConfigurationId: null,
            exportDialogWithBlanks: false,
            exportDialogGroupPrintColor: false,
            allSuppliers: null,
            productConfigurationTemplates: [],
            options: {
                columns: {
                    'Name': {
                        field: 'productConfigurationName',
                        sortable: true,
                        link: this.isAllowedTo('SupplierCentral/getProductConfigurationById') ? (value, data) => {
                            return {
                                name: 'SupplierProductView',
                                params: {
                                    supplierId: this.supplierId,
                                    productConfigurationId: data.productConfigurationId,
                                },
                            }
                        } : null,
                    },
                    'Published': {
                        field: 'productConfigurationStatus',
                        statusChip: true,
                    },
                    'Task (status)': {
                        field: 'taskStatus',
                        statusChip: true,
                        cellRendererParams ({ data }) {
                            return {
                                text: `${data.taskType} (${data.taskStatus})`,
                                statusChipSize: 'small',
                            }
                        },
                    },
                    'Created': {
                        field: 'dateCreated',
                        sortable: true,
                    },
                    'Updated': {
                        field: 'dateUpdated',
                        sortable: true,
                    },
                },

                actions: [
                    {
                        icon: 'fas fa-clone',
                        text: 'Clone',
                        hide: () => {
                            return this.isSupplier || !this.isAllowedTo('SupplierCentral/saveProductConfiguration')
                        },
                        tooltipText: 'Clone this product to another supplier',
                        onClick: ({ data }) => {
                            this.openProductCloneDialog(data)
                        },
                    },
                    {
                        icon: 'fas fa-plus',
                        text: 'Create Comercial Product',
                        hide: () => {
                            return this.isSupplier || !this.isAllowedTo('SupplierCentral/saveProductConfiguration')
                        },
                        tooltipText: 'Create Comercial Product from this template',
                        to: (data) => {
                            return {
                                name: 'CommercialProductAdd',
                                params: {
                                    supplierId: this.supplierId,
                                    productConfigurationId: data.data.productConfigurationId,
                                },
                            }
                        },
                    },
                ],

                quickSearchColumns: ['productConfigurationName'],
                sortModel: [{
                    colId: 'dateUpdated',
                    sort: 'desc',
                }],
            },
            filters: {
                search: '',
            },
        }
    },
    methods: {
        openProductCloneDialog (data) {
            this.cloneProductConfigurationId = data.productConfigurationId
            this.cloneProductDialogShow = true
        },
        openExportDialog () {
            this.exportDialogShow = true
        },
        closeExportDialog () {
            this.exportDialogShow = false
            this.exportDialogConfigurationId = null
            this.exportDialogWithBlanks = false
            this.exportDialogGroupPrintColor = false
        },
        exportForFastEditor () {
            this.downloadAssetsWrapper()
            this.closeExportDialog()
        },
        downloadAssetsWrapper () {
            const url = CONFIG.API.ROUTES.PRODUCTS.TASKS.DOWNLOAD
            const payload = {
                supplierId: this.supplierId,
                withBlanks: this.exportDialogWithBlanks,
                groupPrintColor: this.exportDialogGroupPrintColor,
                productConfigurationTemplateId: this.exportDialogConfigurationId,
            }

            const jobName = [url, ...Object.values(payload).map(JSON.stringify)].join('')
            this.downloadAssets(
                payload,
                url,
                CONFIG.API.ROUTES.PRODUCTS.TASKS.CHECK_TOKEN_RESPONSE,
                'Download Fast Editor',
                jobName
            )
        },
        loadAllSuppliers () {
            const suppliersParams = JSON.parse(JSON.stringify(CONFIG.API.REQUEST_DEFAULT))
            suppliersParams.request.sortModel.push({
                colId: 'supplierName',
                sort: 'asc',
            })

            const suppliersUrl = CONFIG.API.ROUTES.SUPPLIERS.OVERVIEW
            this.addJob(suppliersUrl)
            return this.$api.post(suppliersUrl, suppliersParams).then(({ data }) => {
                this.$set(this, 'allSuppliers', data.data.items)
            }).finally(() => {
                this.finishJob(suppliersUrl)
            })
        },
        loadVirtualSupplier () {
            const url = CONFIG.API.ROUTES.SUPPLIERS.GET.replace('{supplierId}', this.supplierId)
            this.addJob(url)
            return this.$api.get(url).then(({ data }) => {
                this.allSuppliers.push(data.data)
            }).finally(() => {
                this.finishJob(url)
            })
        },
        loadProductConfigurationTemplates () {
            const url = CONFIG.API.ROUTES.SUPPLIERS.PRODUCT_TEMPLATES.replace('{supplierId}', this.supplierId)
            this.addJob(url)
            this.$api.post(url)
                .then(({ data }) => this.$set(this, 'productConfigurationTemplates', data.data))
                .finally(() => this.finishJob(url))
        },
    },
    computed: {
        isVirtualSupplierManagement () {
            return !this.isSupplier && this.supplierId === this.$store.state.auth.user.supplierId
        },
        url () {
            return CONFIG.API.ROUTES.SUPPLIERS.PRODUCTS.replace('{supplierId}', this.supplierId)
        },
        addProductRoute () {
            return {
                name: 'SupplierProductsAdd',
                params: {
                    supplierId: this.supplierId,
                },
            }
        },
        cloneProductRoute () {
            return {
                name: 'SupplierProductsAdd',
                params: {
                    supplierId: this.cloneProductSupplierId,
                },
                query: {
                    cloneProductConfigurationId: this.cloneProductConfigurationId,
                    cloneSupplierId: this.supplierId,
                },
            }
        },
    },
    created () {
        this.loadAllSuppliers().then(() => this.isVirtualSupplierManagement && this.loadVirtualSupplier())
        this.loadProductConfigurationTemplates()
    },
}
</script>
